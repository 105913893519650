body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hover-content-container .hover-content {
  opacity: 0;
  transition: opacity .3s
}

.hover-content-container:hover .hover-content {
  opacity: 100;
  transition: opacity .1s
}

/* This style allows the google places address lookup to be seen in modals */
.pac-container {
  z-index: 1300 !important;
}